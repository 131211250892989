<template>
  <header class="flex items-center justify-between p-6 border-b">
    <div class="mr-10">
      <h1 class="text-3xl font-semibold">
        {{ pageTitle }} <i v-if="loading" class="el-icon-loading"></i>
      </h1>
      <div class="flex flex-col mt-3 text-sm">
        <div class="flex whitespace-nowrap">
          <div class="text-gray-400 mr-1">Количество файлов:</div>
          <div class="text-black font-bold">{{ totalFiles || 0 }}</div>
        </div>
        <div class="flex whitespace-nowrap">
          <div class="text-gray-400 mr-1">Общая длительность:</div>
          <div class="text-black font-bold">
            {{ timeNormalizer(totalDuration) }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <el-select
        v-model="filters.batch_id"
        v-if="user.scopes.includes('admin')"
        placeholder="Батч"
        size="large"
        style="width: 240px"
        @change="handleChangeFilters"
        clearable
      >
        <el-option
          v-for="batch in batches"
          :key="batch._id"
          :value="batch._id"
          :label="batch.batch_name"
        >
          <template #default>
            <div class="w-full flex items-center justify-between">
              <span class="text-medium text-gray-600">{{
                batch.batch_name || "-"
              }}</span>
              <span
                class="ml-3 h-2.5 w-2.5 rounded-full"
                :class="batch.is_active ? 'bg-green-500' : 'bg-gray-400'"
                style="font-size: 12.5px"
              >
              </span>
            </div>
          </template>
        </el-option>
      </el-select>
      <el-select
        v-if="payedPage"
        v-model="filters.action"
        @change="getFiles"
        style="width: 240px"
        size="large"
      >
        <el-option value="" label="По дате изменения"></el-option>
        <el-option value="payed_date" label="По дате оплаты"></el-option>
      </el-select>
      <el-date-picker
        value-format="YYYY-MM-DD"
        @change="handleChangeFilters"
        v-model="filters.date"
        placeholder="Выберите отрезок времени"
        type="daterange"
        style="width: 240px"
        size="large"
      ></el-date-picker>
      <el-select
        v-if="showDownloadButton"
        v-model="filters.annotator"
        placeholder="Аннотатор"
        @change="handleChangeFilters"
        clearable
        filterable
        size="large"
        style="width: 240px"
      >
        <el-option
          v-for="user in users"
          :key="user._id"
          :value="user._id"
          :label="`${user.first_name} ${user.last_name}`"
        ></el-option>
      </el-select>

      <el-select
        v-model="filters.lang"
        placeholder="Язык"
        @change="handleChangeFilters"
        clearable
        size="large"
        style="width: 240px"
      >
        <el-option
          v-for="language in batchLanguages"
          :label="language.title"
          :value="language.value"
          :key="language.value"
          >{{ language.title }}</el-option
        >
      </el-select>

      <el-button
        v-if="showDownloadButton"
        @click="download"
        :disabled="downloading"
        :icon="downloading ? 'el-icon-loading' : ''"
        type="success"
        size="large"
        plain
        >Скачать xlsx</el-button
      >
    </div>
  </header>

  <main class="p-6 pt-1">
    <div class="mt-3 flex justify-end gap-3">
      <el-tooltip
        placement="bottom"
        content="Количество файлов (по фильтру)"
        :show-after="300"
      >
        <div
          class="flex items-center border border-solid p-2 py-1 rounded bg-gray-50 cursor-default"
        >
          <el-icon :size="18" class="mr-2"
            ><Files class="text-gray-700"
          /></el-icon>
          <div class="flex items-center font-bold text-lg mr-1">
            {{ filteredTotalFiles }}
          </div>
        </div></el-tooltip
      >
      <el-tooltip
        placement="bottom"
        content="Общая длительность файлов (по фильтру)"
        :show-after="300"
      >
        <div
          class="flex items-center border border-solid p-2 py-1 rounded bg-gray-50 cursor-default"
        >
          <el-icon :size="18" class="mr-2"
            ><Timer class="text-gray-700"
          /></el-icon>
          <div class="flex items-center font-bold text-lg mr-1">
            {{ timeNormalizer(filteredTotalDuration) }}
          </div>
        </div></el-tooltip
      >
    </div>
    <div class="justify-end items-center flex">
      <el-button
        v-if="payedState && user.scopes.includes('admin')"
        type="success"
        plain
        @click="payForAll"
        class="mt-5"
        >Оплатить все за этот период</el-button
      >
      <el-button
        @click="payDrawer = !payDrawer"
        v-if="selectedItems.length && user.scopes.includes('admin')"
        type="success"
        class="mt-5"
        >Оплатить выбранные файлы</el-button
      >
      <el-button
        v-if="payedPage && userData && userData.batch_access"
        type="primary"
        plain
        @click="SendtoMarkUp"
        class="mt-5"
        >Отправить в разметку</el-button
      >
      <el-button
        v-if="payedPage && userData && userData.batch_access"
        type="danger"
        plain
        @click="SendtoArchived"
        class="mt-5"
        >Удалить</el-button
      >
    </div>
    <div class="h-full mt-5">
      <el-table
        border
        class="h-full"
        :data="files"
        style="height: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelection"
        ref="multiselection"
      >
        <el-table-column
          type="index"
          label="N"
          align="center"
          width="55"
        ></el-table-column>
        <el-table-column label="ID" min-width="300">
          <template #default="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('admin')"
          type="index"
          label="Имя батча"
          min-width="250"
        >
          <template #default="scope">
            {{ scope.row.topic }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('admin')"
          type="index"
          label="Имя файла"
          min-width="250"
        >
          <template #default="scope">
            {{ getFileName(scope.row.filename) }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('admin')"
          label="Длительность файла"
          width="200"
        >
          <template #default="scope">
            <div v-if="scope.row.duration">
              {{ timeNormalizer(scope.row.duration.mix) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Длительность фрагментов" width="220">
          <template v-slot="scope">
            {{ timeNormalizer(scope.row.duration_utterances.mix || 0) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="lang"
          :width="80"
          align="center"
          label="Язык"
        ></el-table-column>
        <el-table-column
          prop="datetime"
          label="Последнее изменение"
          align="center"
          :width="220"
        >
          <template #default="scope">
            <span>
              {{ dateFormat(scope.row.updated_at) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('annotator') && state === '0'"
          label="Дата загрузки"
          width="200"
          align="center"
        >
          <template #default="scope">
            {{ scope.row.created_at }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('annotator') && state === '1'"
          prop="annotator_started"
          label="Дата начала разметки"
          align="center"
          :width="220"
        >
          <template #default="scope">
            {{ scope.row.annotator_started }}
          </template>
        </el-table-column>
        <el-table-column
          prop="annotator"
          label="Статус"
          align="center"
          min-width="180"
          v-if="user.scopes.includes('admin')"
        >
          <template #default="scope">
            <span
              class="bg-gray-200 rounded px-1 text-xs"
              :class="{
                'bg-yellow-500 text-white': scope.row.status === 1,
                'bg-blue-500 text-white': scope.row.status === 2,
                'bg-green-500 text-white': scope.row.status === 3,
                'bg-indigo-500 text-white': scope.row.status === 4,
              }"
            >
              {{ getStatusTitle(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="annotator"
          label="Аннотатор"
          :min-width="250"
          v-if="!user.scopes.includes('annotator')"
        >
          <template #default="scope">
            <div v-if="scope.row.annotator">
              {{ scope.row.annotator.first_name }}
              {{ scope.row.annotator.last_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="annotator_finish"
          label="Дата завершения разметки"
          :min-width="250"
          v-if="
            (user && user.scopes && user.scopes.includes('admin')) ||
            (user &&
              user.scopes &&
              user.scopes.includes('verifier') &&
              state === '2') ||
            (user &&
              user.scopes &&
              user.scopes.includes('annotator') &&
              (state === '2' || state === '3' || state === '4'))
          "
        >
          <template #default="scope">
            {{ scope.row.annotator_finish || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="
            user &&
            user.scopes &&
            user.scopes.includes('verifier') &&
            state === '3'
          "
          label="Дата начала верификации"
          :min-width="250"
        >
          <template #default="scope">
            {{ scope.row.verifier_started }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('admin')"
          label="Длительность аннотации"
          min-width="250"
          ><template #default="scope">{{
            timeNormalizer(scope.row.annotation_duration || 0)
          }}</template></el-table-column
        >
        <el-table-column
          prop="verifier"
          label="Верификатор"
          :min-width="240"
          v-if="user.scopes.includes('admin')"
        >
          <template #default="scope">
            <div v-if="scope.row.verifier">
              {{ scope.row.verifier.first_name }}
              {{ scope.row.verifier.last_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="verifier"
          label="Дата завершения верификации"
          :min-width="250"
          v-if="
            user.scopes.includes('admin') ||
            (user.scopes.includes('verifier') && state === '4')
          "
        >
          <template #default="scope">
            {{ scope.row.verification_finish || "-" }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="user.scopes.includes('admin')"
          label="Длительность верификации"
          min-width="240"
        >
          <template #default="scope">{{
            timeNormalizer(scope.row.verification_duration || 0)
          }}</template></el-table-column
        >
        <el-table-column width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button @click="() => viewFile(scope.row)" type="primary" plain>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 12px; width: 12px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M4 22H20"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M13.8881 3.66293L14.6296 2.92142C15.8581 1.69286 17.85 1.69286 19.0786 2.92142C20.3071 4.14999 20.3071 6.14188 19.0786 7.37044L18.3371 8.11195M13.8881 3.66293C13.8881 3.66293 13.9807 5.23862 15.3711 6.62894C16.7614 8.01926 18.3371 8.11195 18.3371 8.11195M13.8881 3.66293L7.07106 10.4799C6.60933 10.9416 6.37846 11.1725 6.17992 11.4271C5.94571 11.7273 5.74491 12.0522 5.58107 12.396C5.44219 12.6874 5.33894 12.9972 5.13245 13.6167L4.25745 16.2417M18.3371 8.11195L11.5201 14.9289C11.0584 15.3907 10.8275 15.6215 10.5729 15.8201C10.2727 16.0543 9.94775 16.2551 9.60398 16.4189C9.31256 16.5578 9.00282 16.6611 8.38334 16.8675L5.75834 17.7426M5.75834 17.7426L5.11667 17.9564C4.81182 18.0581 4.47573 17.9787 4.2485 17.7515C4.02128 17.5243 3.94194 17.1882 4.04356 16.8833L4.25745 16.2417M5.75834 17.7426L4.25745 16.2417"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
              </svg>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="mt-3 flex justify-center">
        <el-pagination
          v-if="filteredTotalFiles > 15"
          background
          layout="pager"
          :total="filteredTotalFiles"
          :default-current-page="defaultPage"
          :current-page="filters.page"
          @update:current-page="handleChangePage"
          :page-size="15"
        />
      </div>
    </div>
  </main>
  <el-dialog v-model="payDrawer" title="Выберите дату">
    <el-date-picker
      v-model="formPay.date"
      type="date"
      value-format="YYYY-MM-DD"
    ></el-date-picker>
    <div class="text-right mt-5">
      <el-button :disabled="!formPay.date" @click="payFileList"
        >Оплатить</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getSection } from "./shared/sections";
import {
  exportToExcel,
  getFiles,
  getNewFiles,
  getPreviewFiles,
  tagForModel,
} from "@/api/files";
import { getStatus as _getStatus } from "./shared/statuses";
import { format, intervalToDuration } from "date-fns";
import { getUser as _gertUser, getUsers } from "@/api/users";
import { getBatchesList } from "@/api/batches";
import apiClient from "@/api/api-client";
import { Files, Timer } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";

export default {
  components: { Timer, Files },
  props: {
    state: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      files: [],
      users: [],
      userData: [],
      batches: [],
      totalFiles: 0,
      totalDuration: 0,
      filteredTotalFiles: 0,
      filteredTotalDuration: 0,
      topics: [
        { key: "egov", title: "Egov" },
        { key: "tson", title: "ЦОН" },
        { key: "sp", title: "По пенсионным вопросам" },
        { key: "ashyq", title: "Ashyq" },
        { key: "sl", title: "По трудом вопросам" },
      ],
      importForm: {
        topic: null,
        lang: null,
        limit: null,
      },
      loadingImportFiles: false,
      importFormRules: {
        limit: [
          {
            required: true,
            message: "Это обязательное поле",
            trigger: ["blur", "change"],
          },
          {
            max: 100,
            min: 1,
            message: "Количество файлов должна быть меньше 100",
            trigger: ["blur", "change"],
          },
        ],
      },
      filters: {
        page: 1,
        status: undefined,
        annotator: undefined,
        lang: null,
        is_model: null,
        batch_id: null,
        date: [],
        action: "",
        sort: "updated_at:desc",
      },
      importDrawer: false,
      importFiles: [],
      selectedItems: [],
      selectedItemsImport: [],
      formPay: {
        selectedFiles: [],
        date: "",
      },
      payDrawer: false,
      analytics: {},
    };
  },
  computed: {
    sidebarItem() {
      return getSection(this.state === "all" ? this.state : +this.state);
    },
    pageTitle() {
      return this.sidebarItem.title;
    },
    fileId() {
      return this.$route.query.fileId;
    },
    payedState() {
      if (this.state === "all") return false;
      return +this.state === 4;
    },
    payedPage() {
      if (this.state === "all") return false;
      return +this.state === 5;
    },
    defaultPage() {
      return +(this.$route.query.page || 1);
    },
    user() {
      return this.$store.state.user.user;
    },
    showDownloadButton() {
      return (
        this.user.scopes.includes("admin") ||
        this.user.scopes.includes("verifier")
      );
    },
    batchLanguages() {
      return [
        { title: "ru", value: "ru" },
        { title: "kz", value: "kz" },
      ];
    },
  },
  created() {
    this.filters.sort =
      this.state === "0" ? "updated_at:asc" : "updated_at:desc";
  },
  async mounted() {
    if (this.state !== "all") {
      this.filters.status = +this.state;
    }
    this.filters.page = this.defaultPage;
    await this.getUser();
    await this.getFiles();
    await this.getBatches();
    this.users = await getUsers();
  },
  watch: {
    async state() {
      if (this.state !== "all") {
        this.filters.status = +this.state;
      } else {
        this.filters.status = undefined;
      }
      this.filters.lang = null;
      this.filters.page = 1;
      this.filters.batch_id = null;
      this.filters.sort =
        this.state === "0" ? "updated_at:asc" : "updated_at:desc";
      await this.getFiles();
    },
  },
  methods: {
    getFileName(filename) {
      if (filename) {
        return filename.split("/").pop();
      }
    },
    async getUser() {
      this.loading = true;
      const user = await _gertUser(this.user._id);
      this.loading = false;
      this.userData = user || [];
    },
    async getBatches() {
      try {
        const { data } = await getBatchesList();
        this.batches = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    showPayForm() {
      this.payDrawer = !this.payDrawer;
    },
    async handleChangePage(val) {
      console.log("CHANGE PAGE", val);
      this.filters.page = val;
      this.$router.push({ query: { page: val } });
      await this.getFiles();
    },
    tableRowClassName({ row }) {
      if (this.fileId === row._id) {
        return "bg-blue-50";
      }
      return "";
    },
    async SendtoMarkUp() {
      try {
        await ElMessageBox.confirm(
          "Вы уверены, что хотите отправить в разметку?",
          "Подтверждение",
          {
            confirmButtonText: "Да",
            cancelButtonText: "Отмена",
            type: "warning",
          }
        );

        const json = {}; // Пустой JSON-объект
        const response = await apiClient.post("/files", json, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.data._success) {
          ElMessage({
            message: "Запрос успешно отправлен!",
            type: "success",
          });
          window.location.reload();
        }
      } catch (error) {
        if (error !== "cancel") {
          console.error(error);
          ElMessage({
            message: "Ошибка при отправке запроса",
            type: "error",
          });
        }
      }
    },
    async SendtoArchived() {
      try {
        await ElMessageBox.confirm(
          "Вы уверены, что хотите удалить?",
          "Подтверждение",
          {
            confirmButtonText: "Да",
            cancelButtonText: "Отмена",
            type: "warning",
          }
        );

        const response = await apiClient.post("/files", {
          action: "delete",
        });

        if (response.data._success) {
          ElMessage({
            message: "Файл успешно архивирован!",
            type: "success",
          });
          window.location.reload();
        }
      } catch (error) {
        if (error !== "cancel") {
          console.error("Ошибка при отправке запроса:", error);
          ElMessage({
            message: "Ошибка при отправке запроса",
            type: "error",
          });
        }
      }
    },
    async getFiles() {
      this.loading = true;
      let filters = this.getFilters();

      const data = await getFiles(filters);
      this.analytics = data.analytics;
      this.files = [...data.data];
      this.files = this.files.map((k) => {
        if (!("is_model" in k)) {
          return { ...k, is_model: 0 };
        }
        return k;
      });
      this.totalFiles = data.general.total;
      this.totalDuration = data.general.seconds || 0;
      this.filteredTotalFiles = data.filtered.total || 0;
      this.filteredTotalDuration = data.filtered.seconds || 0;
    },
    async handleChangeFilters() {
      console.log("CHANGE FILTERS");
      this.filters.page = 1;
      await this.getFiles();
    },
    viewFile(row) {
      if (row.one_audio) {
        this.$router.push({
          name: "file-view-full-audio",
          params: { state: row.status, fileId: row._id },
        });
      } else {
        this.$router.push({
          name: "file-view",
          params: { state: row.status, fileId: row._id },
        });
      }
    },
    timeNormalizer(timeInSeconds) {
      let pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      };

      // Calculate hours, minutes, and seconds
      let hours = Math.floor(timeInSeconds / 3600);
      let minutes = Math.floor((timeInSeconds % 3600) / 60);
      let seconds = Math.floor(timeInSeconds % 60);

      // Return formatted time
      if (hours > 0) {
        return pad(hours, 2) + ":" + pad(minutes, 2) + ":" + pad(seconds, 2); // HH:MM:SS
      } else {
        return pad(minutes, 2) + ":" + pad(seconds, 2); // MM:SS
      }
    },
    getStatusTitle(state) {
      const status = _getStatus(state);
      return (status && status.title) || state;
    },
    dateFormat(date) {
      if (!date) return "";
      return format(new Date(date), "dd MMM yyyy, HH:mm");
    },
    secondsToTime(time) {
      let duration = intervalToDuration({ start: 0, end: (time || 0) * 1000 });
      return `${duration.hours}:${duration.minutes}:${duration.seconds}`;
    },
    async download() {
      this.downloading = true;
      let params = {};
      if (this.filters.date && this.filters.date.length > 1) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      await exportToExcel({
        status: +this.state,
        annotator: this.filters.annotator,
        ...params,
      });
      this.downloading = false;
    },
    handleSelection(val) {
      console.log(val);
      this.selectedItems = val.map((k) => k._id);
    },
    numberFormat(number) {
      return number && new Intl.NumberFormat().format(number);
    },
    payForAll() {
      this.payDrawer = true;
      this.filters.action = "all_files";
    },
    getFilters() {
      let params = { ...this.filters };
      if (this.filters.date && this.filters.date.length > 1) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      delete params.action;
      if (this.filters.action) {
        params.action = this.filters.action;
      }
      if (this.filters.batch_id) {
        params.batch_id = this.filters.batch_id;
      }
      if (this.filters.is_model) {
        params.is_model = this.filters.is_model;
      }
      delete params.date;
      return params;
    },
    async getForImportFiles() {
      let validation = await this.$refs["import-form"].validate();
      if (validation) {
        let params = { ...this.importForm };
        this.loadingImportFiles = true;
        let data = await getPreviewFiles(params);
        this.loadingImportFiles = false;
        console.log(data);
        this.importFiles = data.data;
      }
    },
    async importNewFiles() {
      let files_ids = this.selectedItemsImport.map((k) => k.id);
      let data = await getNewFiles(files_ids);
      if (data._success) {
        console.log(files_ids);
        this.clearImportForm();
        this.getFiles();
      }
    },
    clearImportForm() {
      this.importForm.topic = null;
      this.importForm.lang = null;
      this.importForm.limit = null;
      this.importFiles = [];
      this.selectedItemsImport = [];
      this.importDrawer = false;
    },
    async handleSelectionImport(val) {
      this.selectedItemsImport = val;
    },
    async tagForModel(file_id, state) {
      let action = "unmarked";
      if (state) {
        action = "marked";
      }
      let data = tagForModel(file_id, action);
      console.log(data);
    },
  },
};
</script>

<style>
.el-table .bg-blue-50 {
  background: rgba(239, 246, 255, 1);
}
</style>
